<template>
    <v-menu
        ref="menu"
        v-model="menu"
        class="route-datepicker__menu-block"
        :close-on-content-click="false"
        transition="scale-transition"
        offset-y
        width="140px"
        min-width="140px">
        <template #activator=" { on }">
            <div class="route-datepicker-field__container" v-on="on">
                <v-text-field
                    class="route-datepicker-field"
                    readonly
                    outlined
                    :value="dateText"
                    :label="label">
                    <!-- <template #append>
                        <img
                            src="../../assets/Calendar3.svg"
                            class="prepend-icon" />
                    </template> -->
                </v-text-field>
            </div>
            <div class="v-messages error--text" role="alert">
                <div class="v-messages__wrapper">
                    <div class="v-messages__message"> {{ errorMessages }}</div>
                </div>
            </div>
        </template>
        <v-date-picker
            :type="type"
            :value="value"
            :max="max"
            :min="min"
            no-title
            color="#E2231A"
            locale="ru"
            @input="updateDate" />
    </v-menu>
</template>

<script>
    import moment from 'moment'
    export default {
        props: {
            type: {
                type: String,
                default: 'date',
            },
            value: {
                type: String,
                default: '',
            },
            max: {
                type: String,
                default: '',
            },
            min: {
                type: String,
                default: '',
            },
            label: {
                type: String,
                default: '',
            },
            errorMessages: {
                type: String,
                default: '',
            },
        },
        data: () => ( {
            menu: false,
            modal: false,
            menu2: false,
        }),
        computed: {
            dateText() {
                if (this.value) {
                    let newValue = [];
                    newValue.push(moment(this.value).format('LL'));
                    return newValue    
                } 
                return '';
            }
        },
        methods: {
            updateDate(value) {
                this.menu = false;
                this.$emit('change', value);
            },
        },
    };
</script>
<style lang="scss">
.route-datepicker-field {
    font-family: Inter;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 140%;
    letter-spacing: -0.02em;
    color: #273155;
    height: 20px;
    width: 150px;
    margin-bottom: 16px !important;
    &__container {
        height: 20px;
    }
    legend {
        width: 0 !important;
        height: 0 !important;
    }
    &.theme--light.v-input input {
       position:absolute;
       padding: 0;
       text-align: end;
    }
    .v-messages {
    min-height: 0 !important;
    }
    .v-text-field__details {
        min-height: 0 !important;
        margin-bottom: 0 !important;
    }

    .v-input__slot {
        border-radius: 10px;
        box-shadow: rgba(30, 28, 46, 0.03);
        background: #fff !important;
        margin-bottom: 0;
        height: 20px !important;
    }
    &.v-text-field.v-text-field--enclosed:not(.v-text-field--rounded) > 
    .v-input__control > .v-input__slot, 
    .route-datepicker-field .v-text-field.v-text-field--enclosed .v-text-field__details{
        padding: 0;
    }
    .v-messages {
        min-height: 0 !important;
    }
    &.v-messages .error--text {
        min-height: 0 !important;
    }
    &.v-text-field--outlined fieldset {
        // border: 1px solid #EBEBEB;
        border: none;
    }
    &.v-text-field--outlined.v-input--is-focused fieldset,
    &.v-text-field--outlined.v-input--has-state fieldset {
        // border: 1px solid #EBEBEB;
        border: none;
    }
    &.v-text-field--outlined.error--text.v-input--is-focused fieldset,
    &.v-text-field--outlined.error--text.v-input--has-state fieldset {
        // border: 1px solid #ff5252;
        border: none;
    }

    .theme--light.v-label {
        font-family: Inter;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 140%;
        letter-spacing: -0.02em;
        color: #A9AEB2;
        top: 0;
        left: auto !important;
        right: 0 !important;
        &.v-label--active {
            position: absolute;
            display: none;
        }
    }
    .v-application .error--text {
        display: none;
    }
    &.v-text-field > .v-input__control > .v-input__slot > .v-text-field__slot {
        height: 20px;
    }
    &.v-text-field.v-text-field--enclosed:not(.v-text-field--rounded) > .v-input__control > .v-input__slot, .v-text-field.v-text-field--enclosed .v-text-field__details {
        min-height: 20px;
    }
}

.v-messages {
    min-height: 0 !important;
}
.v-text-field__details {
    min-height: 0 !important;
    min-width: 0 !important;
}
</style>

